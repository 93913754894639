import React, { useState, useEffect } from "react";
import App from "../App";
import MobileMessage from "./MobileMessage";
import { HelmetProvider } from "react-helmet-async";

function ScreenSizeCheck() {
  // Component State
  const [, setIsMobile] = useState(false);

  // Side Effect -> Screen Size Checking
  useEffect(() => {
    // Check the screen width when the component mounts
    function checkScreenWidth() {
      if (window.innerWidth < 1100) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    // Listen for window resize events to update the screen width
    window.addEventListener("resize", checkScreenWidth);
    checkScreenWidth();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return (
    <>
      {false ? (
        <MobileMessage />
      ) : (
        <HelmetProvider>
          <App />
        </HelmetProvider>
      )}
    </>
  );
}

export default ScreenSizeCheck;
